import { BrokerMetricsObjects, Metric } from "@/types/main";
import { compareDesc } from "date-fns/fp/compareDesc";
import { parse, format, compareAsc } from "date-fns";
import { SECOND_MULTIPLIER } from "@/constants/broker_metrics";

export function convertToMap(metrics: Metric[], initialObject: Record<string, Metric>) {
	return metrics.reduce((prev: Record<string, Metric>, cur) => {
		prev[cur.end_time] = cur;
		return prev;
	}, initialObject);
}

export function combineAndSortDateLabels(metricDateLabels: (string | number)[][]) {
	const allUniqueDateLabels: Set<string | number> = new Set();
	metricDateLabels.forEach((dateLabels) => {
		dateLabels.forEach((label) => {
			allUniqueDateLabels.add(label);
		});
	});
	return Array.from(allUniqueDateLabels).sort(compareDesc);
}

export function parseAndFormatTime(dateString: string, parsingFor: string, parsingTo: string): string {
	const r = parse(dateString, parsingFor, new Date());
	if (isNaN(r.getTime())) {
		throw `Improperly formed date ${dateString}`;
	}
	return format(r, parsingTo);
}

export function createChartBars(data: [string, BrokerMetricsObjects][]) {
	return data.map(([broker, brokerData]) => {
		const brokerTrace: any = {
			name: broker,
			x: Object.keys(brokerData.metrics)
				.sort(compareAsc)
				.map((d) => parseAndFormatTime(d, "yyyy-MM-dd'T'HH:mm:SS", "dd MMM HH:mm")),
			q1: [],
			q3: [],
			mean: [],
			median: [],
			// sd: [],
			lowerfence: [],
			upperfence: [],
			type: "box",
			showlegend: true,
			y: [],
		};

		Object.values(brokerData.metrics).forEach((metric) => {
			brokerTrace.q1.push(metric.percentile_25 * SECOND_MULTIPLIER);
			brokerTrace.q3.push(metric.percentile_75 * SECOND_MULTIPLIER);
			brokerTrace.mean.push(metric.mean * SECOND_MULTIPLIER);
			brokerTrace.median.push(metric.median * SECOND_MULTIPLIER);
			brokerTrace.lowerfence.push(metric.percentile_10 * SECOND_MULTIPLIER);
			brokerTrace.upperfence.push(metric.percentile_90 * SECOND_MULTIPLIER);
			// brokerTrace.sd.push(metric.standard_deviation * SECOND_MULTIPLIER);
		});
		return brokerTrace;
	});
}
