import { THEME as THEME_COLORS } from "@/constants/broker_metrics";
import LogoBg from "@/assets/logo32.svg";
import { BrokerMetricsObjects } from "@/types/main";
import { createChartBars } from "@/utils/convert";
import { useEffect, useMemo, useState } from "react";
import type { Config, PlotlyDataLayoutConfig } from "plotly.js";
import Plot from "react-plotly.js";
import { useMediaQuery, MOBILE_MEDIA_QUERY } from "@/hooks/useMediaQuery";

function PlotlyBox({ data }: { data: Record<string, BrokerMetricsObjects> }) {
  const [theme, setTheme] = useState<keyof typeof THEME_COLORS>("dark");
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
    function detectThemeChange(event: MediaQueryListEvent) {
      setTheme(event.matches ? "dark" : "light");
    }
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", detectThemeChange);
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", detectThemeChange);
    };
  }, []);

  const plotlyData = useMemo(
    () => createChartBars(Object.entries(data)),
    [data]
  );
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const layoutConfig: PlotlyDataLayoutConfig["layout"] = {
    images: [
      {
        x: 0.5,
        y: 0.3,
        sizex: 0.3,
        sizey: 0.3,
        opacity: 0.2,
        source: "/logo.svg",
        xanchor: "right",
        xref: "paper",
        yanchor: "bottom",
        yref: "paper",
      },
    ],
    title: "Broker response time comparison by AlgoTest",
    yaxis: {
      title: "Durations (ms)",
      zeroline: false,
      color: THEME_COLORS[theme].tickTextColor,
      autorange: true,
      rangemode: "nonnegative",
    },
    xaxis: {
      type: "category",
      dtick: 6,
      color: THEME_COLORS[theme].tickTextColor,
    },
    boxmode: "group",
    plot_bgcolor: THEME_COLORS[theme].bg,
    paper_bgcolor: THEME_COLORS[theme].bg,
    autosize: true,
    legend: {
      x: 0,
      y: 1.28,
      font: {
        family: "sans-serif",
        size: 12,
        color: "#000",
      },
      orientation: "h",
      bgcolor: "#E2E2E2",
      bordercolor: "#FFFFFF",
      borderwidth: 1,
    },
    uirevision: "true",
    modebar: {
      uirevision: "true",
      orientation: 'v'

    },
  };

  if (isMobile) {
    layoutConfig.margin = {
      l: 0,
      r: 0,
    };
  }
  const config: Partial<Config> = {
    responsive: true,
    scrollZoom: false,
    displaylogo: false,
  };
  return (
    <Plot
      className="w-full h-full bg-primary"
      data={plotlyData as any}
      config={config}
      layout={layoutConfig}
      useResizeHandler={true}
    />
  );
}

export default PlotlyBox;
