import type { Metric } from "@/types/main";
export const SECOND_MULTIPLIER = 1e3;
export const BEAUTIFUL_COLORS = [
	"#40F99B",
	"#E85D75",
	"#D6FFF6",
	"#FF8484",
	"#FFFB46",
	"#49B6FF",
	"#F4D35E",
	"#EF3054",
];

export const ON_PAN_BUCKETS_TO_FETCH = 50;

// TODO: replace this with tailwind color
export const THEME = {
	light: {
		bg: "#ffffff",
		tickTextColor: "#242424",
	},
	dark: {
		bg: "#1d232a",
		tickTextColor: "#e9e9e9",
	},
};

export const HEADERS: (keyof Metric)[] = [
	"broker",
	"end_point",
	"start_time",
	"end_time",
	"mean",
	"percentile_10",
	"percentile_25",
	"median",
	"percentile_75",
	"percentile_90",
	"standard_deviation",
];

export const CriteriaMap: Record<string, string> = {
	place_order: "Place Order",

	modify_order: "Modify Order",

	cancel_order: "Cancel Order",
};
