"use client";
import { Suspense, useEffect, useState } from "react";
import BrokerMetrics from "./BrokerMetrics";
import { BrokerMetricsMetaData } from "@/types/main";
import LoadingSpinner from "./LoadingSpinner";

function Home() {
  const [brokerMetricsMetaData, setBrokerMetricsMetaData] =
    useState<BrokerMetricsMetaData | null>(null);

  useEffect(() => {
    (async function () {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/meta`);
      if (res.ok) setBrokerMetricsMetaData(await res.json());
    })();
  }, []);

  return (
    <Suspense fallback={<div className="text-8xl">Loading...</div>}>
      <div>
        {brokerMetricsMetaData ? (
          <BrokerMetrics
            brokers={brokerMetricsMetaData.brokers}
            end_points={brokerMetricsMetaData.end_points}
          />
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </Suspense>
  );
}

export default Home;
