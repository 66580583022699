import React, { ReactNode } from "react";

function LabelWrapper({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) {
  return (
    <div className="flex gap-2 flex-col ">
      <label className="text-slate-400 text-xs" htmlFor="broker-dropdown">
        {label}
      </label>
      {children}
    </div>
  );
}

export default LabelWrapper;
