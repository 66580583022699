import type { BrokerMetricsQueryParams, Metric } from "@/types/main";
import { ON_PAN_BUCKETS_TO_FETCH } from "@/constants/broker_metrics";

export async function fetchSlice(
    direction: "desc" | "asc",
    edgeDate: string,
    broker: string,
    end_point: string,
    limit: number = ON_PAN_BUCKETS_TO_FETCH,
): Promise<Metric[]> {
    const fetchParams: BrokerMetricsQueryParams = {
        broker,
        end_point,
        limit: limit.toString(),
        direction: direction.toString(),
    };

    if (direction == "asc") {
        fetchParams.start = edgeDate;
    } else {
        fetchParams.end = edgeDate;
    }
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/buckets?${new URLSearchParams(
            fetchParams as Record<string, string>,
        ).toString()}`,
    );
    if (res.ok) {
        return await res.json();
    }
    return [];
}

export async function fetchBrokerData(
    start: string,
    end: string,
    broker: string,
    end_point: string,
    limit: number = ON_PAN_BUCKETS_TO_FETCH,
    direction: "asc" | "desc" = "asc",
): Promise<Metric[]> {
    const fetchParams: BrokerMetricsQueryParams = {
        broker,
        end_point,
        start,
        end,
        limit: limit.toString(),
        direction,
    };

    const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/buckets?${new URLSearchParams(
            fetchParams as Record<string, string>,
        ).toString()}`,
    );
    if (res.ok) {
        return await res.json();
    }
    return [];
}
