import ChevDown from "@/components/UI/ChevDown";
function MultiSelect({
  brokers,
  placeHolder,
  selectedBrokers,
  toggleBroker,
  onSelectAll,
}: {
  brokers: string[];
  placeHolder: string;
  selectedBrokers: string[];
  toggleBroker: (broker: string) => void;
  onSelectAll: () => void;
}) {
  return (
    <div>
      <div className="dropdown dropdown-hover">
        <div
          tabIndex={0}
          role="select"
          className="flex items-center cursor-pointer relative gap-3 justify-between select-bordered p-1 bg-transparent border-[0.2px]  rounded-sm w-[10rem] text-[16.5px] font-normal"
        >
          <div>{placeHolder}</div>
          <div className="">
            <ChevDown />
          </div>
        </div>
        <div
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          {brokers.length !== 0 && (
            <div key={"all"} className="form-control w-full">
              <label className="label cursor-pointer flex gap-2 justify-between w-full">
                <input
                  type="checkbox"
                  checked={
                    brokers.length !== 0 &&
                    JSON.stringify(selectedBrokers) === JSON.stringify(brokers)
                  }
                  onChange={() => onSelectAll()}
                  className="checkbox"
                />
                <div className="label-text w-full flex-grow ml-2">
                  Select All
                </div>
              </label>
            </div>
          )}
          {brokers.map((broker) => {
            return (
              <div key={broker} className="form-control w-full">
                <label className="label cursor-pointer flex gap-2 justify-between w-full">
                  <input
                    type="checkbox"
                    checked={selectedBrokers.includes(broker)}
                    onChange={() => toggleBroker(broker)}
                    className="checkbox"
                  />
                  <div className="label-text w-full flex-grow ml-2">
                    {broker}
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MultiSelect;
